<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            ref="type"
                            v-model="form.type"
                            label="Tipo"
                            :items="types"
                            outlined
                            :rules="[() => !!form.type || 'Es necesario seleccionar un tipo']"
                            hide-details="auto"
                            required>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="correlative"
                            v-model="form.correlative"
                            label="Correlativo"
                            outlined
                            :rules="[() => !!form.correlative || 'Es necesario ingresar correlativo']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="length"
                            v-model="form.length"
                            label="Longitud de serie"
                            type="number"
                            outlined
                            :rules="[() => !!form.length || 'Es necesario ingresar longitud de serie']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="serie_start_at"
                            v-model="form.serie_start_at"
                            label="Inicio de serie"
                            type="number"
                            outlined
                            :rules="[() => !!form.length || 'Es necesario ingresar inicio de serie']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <!--
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.sede_id"
                            :items="sedes"
                            label="Sede"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            @change="handleSelectSede"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                -->
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="form.office_id"
                            :items="offices"
                            label="Sucursal"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'CreateUpdate',
    data () {
        return {
            drawer: false,
            types: ['BOLETA', 'FACTURA', 'NOTA DE CREDITO', 'TICKET'],
            action: null,
            titleForm: null,
            form: {
                id: null,
                sede_id: null,
                office_id: null,
                type: null,
                correlative: null,
                length: null,
                serie_start_at: null
            },            
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('sedes', [
            'sedes'
        ]),
        ...mapState('offices', [
            'offices'
        ]),
    },
    methods: {
        ...mapActions('offices', [
            'getOffices',
            'run_set_offices'
        ]),
        ...mapActions('vouchers', [
            'store',
            'update'
        ]),
        async show (title, action, voucher = null) {
            await this.clearData();
            this.titleForm = title;
            this.action = action;
            if (voucher) {
                this.setVoucher(voucher);
            }
            this.drawer = !this.drawer;
        },
        clearData () {
            this.form.id = null;
            this.form.sede_id = null;
            this.form.office_id = null;
            this.form.type = null;
            this.form.correlative = null;
            this.form.length = null;
            this.form.serie_start_at = null;
            this.clearErrors();
        },
        setVoucher (voucher) {
            this.form.id = voucher.id;
            this.form.sede_id = voucher.sede_id;
            this.form.office_id = voucher.office_id;
            this.form.type = voucher.type;
            this.form.correlative = voucher.correlative;
            this.form.length = voucher.length;
            this.form.serie_start_at = voucher.serie_start_at;
        },
        closeForm () {
            this.clearData();
            this.drawer = false;
        },
        handleCancel () {
            this.closeForm();
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleSelectSede(value){
            if(value !== undefined && value !== null){
                let filters = {
                    sede_id: value
                }
                await this.getOffices(filters);
            } else {
                this.run_set_offices([]);
            }
        },
        async handleStore () {
            if (!this.existErrors()) {
                await this.store(this.form)
                this.closeForm()
            } 
        },
        async handleUpdate() {
            if (!this.existErrors()) {
                await this.update(this.form);
                this.closeForm();
            }            
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        }
    }
}
</script>

<style scoped>

</style>