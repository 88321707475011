<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Filtrar datos de comprobantes</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            ref="type"
                            v-model="filters.type"
                            label="Tipo"
                            :items="types"
                            outlined
                            hide-details>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="correlative"
                            v-model="filters.correlative"
                            label="Correlativo"
                            outlined
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                <!--
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.sede_id"
                            :items="sedes"
                            label="Sede"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            @change="handleSelectSede"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                -->
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.office_id"
                            :items="offices"
                            label="Sucursal"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'Filters',
    data () {
        return {
            drawer: false,
            types: ['BOLETA', 'FACTURA', 'NOTA DE CREDITO', 'TICKET'],
            filters: {
                type: null,
                correlative: null,
                sede_id: null,
                office_id: null,
                company_id: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('sedes', [
            'sedes'
        ]),
        ...mapState('offices', [
            'offices'
        ]),
    },
    methods: {
        ...mapActions('offices', [
            'getOffices',
            'run_set_offices'
        ]),
        ...mapActions('vouchers', [
            'getVouchers'
        ]),
        show() {
            this.drawer = !this.drawer;
        },
        closeForm() {
            this.drawer = false
        },
        clearData() {
            this.filters.type = null;
            this.filters.correlative = null;
            this.filters.sede_id = null;
            this.filters.office_id = null;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleClearFilters() {
            this.clearData()
            await this.getVouchers(this.filters)
        },
        async handleSearch() {
            await this.getVouchers(this.filters)
            this.closeForm()
        },
        async handleSelectSede(value){
            if(value !== undefined && value !== null){
                let filters = {
                    sede_id: value
                }
                await this.getOffices(filters);
            } else {
                this.run_set_offices([]);
            }
        },
    }
}
</script>

<style scoped>

</style>